@import "../../styles/_chunk";

$summer-sale-burgundy: #64182a;

.SummerSaleModal {
  .SummerSaleModal__content {
    padding-top: 2rem;
    position: relative;
    z-index: 2;

    .SummerSaleModal__logoTitle {
      width: 100%;
      padding: 1rem 2rem;

      img {
        width: 100%;
      }
    }

    .SummerSaleModal__rewardsContainer {
      margin-top: 1rem;

      .SummerSaleModal__rewardContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        width: 100%;

        .SummerSaleModal__rewardSpend {
          text-transform: uppercase;
          color: $white;
          background-color: $summer-sale-burgundy;
          font-weight: 700;
          margin-bottom: 0;
        }

        .SummerSaleModal__rewardMessage {
          background-color: $white;
          text-transform: uppercase;
          font-weight: 700;
          text-align: center;
          padding: 1rem;
          margin-bottom: 0;
          width: 90%;

          @include media-breakpoint-up(sm) {
            width: 80%;
          }

          @include media-breakpoint-up(md) {
            width: 60%;
          }
        }
      }
    }

    .SummerSaleModal__ctaButtonContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }

    .SummerSaleModal__ctaButton {
      background-color: $primary-core;
      font-weight: 700;
      padding: 0.5rem;
      color: $white;
      text-transform: uppercase;
      width: 65%;
      text-align: center;
    }
  }

  .SummerSaleModal__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
